// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/taxDeductible.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/donors.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../../assets/eye.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, ".reports-view{padding:20px}.report-panels{display:grid;grid-template-columns:1fr 1fr 1fr 1fr;grid-column-gap:20px;min-height:540px;height:100%}.report-panels .panel{border:1px solid #e3e8ef;border-radius:20px;position:relative;display:block;text-align:center;padding:20px 20px 100px 20px;text-decoration:none;color:#484a50}.report-panels .panel:hover{background:#f6f9fa}.report-panels .panel h3{color:#484a50}.report-panels .panel .value{font-size:48px;color:#484a50}.report-panels .panel .value span{display:block;font-size:14px}.report-panels .panel .selector{text-align:center;font-size:48px;padding:0 25px;font-weight:700;border:1px solid #e3e8ef;color:#484a50;border-radius:8px}.report-panels .panel .icon{display:inline-block;margin-top:30px;margin-bottom:30px;background-size:100% auto}.report-panels .panel .icon.icon-coin{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");width:55px;height:49px}.report-panels .panel .icon.icon-people{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");width:54px;height:53px}.report-panels .panel .icon.icon-eye{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");width:74px;height:65px}.report-panels .panel .panel-footer{position:absolute;bottom:20px;left:0;width:100%}.report-panels .panel .panel-footer span{display:block}.report-panels .panel.panel-donation .value strong{color:#88ee9a}", ""]);
// Exports
module.exports = exports;
