<template>
	<screen class="reports-view" size="large">
		<h1 slot="title">General Reports</h1>
		<section class="report-panels" slot="body">
			<router-link
				:to="{ name: 'reports.donations' }"
				class="panel panel-donation"
			>
				<h3>Forecasted Donations</h3>
				<div class="icon icon-coin"></div>
				<div class="value">
					<strong>{{ numberFormat(general.pendingDeposit) }}</strong>
					<span>accumulated change this month</span>
				</div>
				<div class="panel-footer">
					<div class="subscript">
						<strong>{{ numberFormat(general.donatedYTD) }}</strong>
						donated YTD
					</div>
					<Roundup-Button color="primary" class="mt-5"
						>View Report</Roundup-Button
					>
				</div>
			</router-link>
			<router-link :to="{ name: 'reports.donors' }" class="panel">
				<h3>New Donors</h3>
				<div class="icon icon-people"></div>
				<div class="value">
					<strong>{{
						general.newDonors > 0 ? '+' + general.newDonors : 0
					}}</strong>
					<span>donors this week</span>
				</div>
				<div class="panel-footer">
					<div class="subscript">
						<strong>{{ general.totalDonors }}</strong> active donors
					</div>
					<Roundup-Button color="primary" class="mt-5"
						>View Report</Roundup-Button
					>
				</div>
			</router-link>
			<a @click.prevent="handleMonthlyDownload" class="panel">
				<h3>Monthly Report</h3>
				<div class="value">
					<strong>{{ getLastMonth() }}</strong>
				</div>
				<div class="panel-footer">
					<Roundup-Button color="primary" class="mt-5"
						>Download</Roundup-Button
					>
				</div>
			</a>
			<!-- <div class="panel">
				<h3>Tax Report</h3>
				<div class="value">
					<strong>2021 Tax Report</strong>
				</div>
				<p>We show the total donation for each user for 2021.</p>
				<p>With &#9829;, <br />The RoundUp Team</p>
				<a class="panel-footer" @click.prevent="handleTaxDownload">
					<Roundup-Button color="primary" class="mt-5"
						>Download</Roundup-Button
					>
				</a>
			</div> -->
			<div class="panel">
				<h3>Yearly Report</h3>
				<ui-label
					:style="{
						textAlign: 'center',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						marginTop: '20px'
					}"
				>
					<select class="value selector" v-model="year">
						<option
							v-for="(yr, idx) in getYears()"
							:key="idx"
							:style="{
								display: 'flex',
								alignContent: 'center',
								alignItems: 'center',
								justifyContent: 'center'
							}"
						>
							{{ yr }}
						</option>
					</select>
				</ui-label>
				<p>
					We show each donation for each user by date. So it's easy to
					upload into your CRM.
				</p>
				<p>With &#9829;, <br />The RoundUp Team</p>
				<p></p>
				<a class="panel-footer" @click.prevent="handleYearlyDownload">
					<Roundup-Button color="primary" class="mt-5"
						>Download</Roundup-Button
					>
				</a>
			</div>
		</section>
	</screen>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { numberFormat, getLastMonth } from '../../../utilities'
import download from '@/mixins/download'
import UiLabel from '../../ui/Label'
import Screen from '../../ui/Screen'
import UiButton from '../../ui/Button'

export default {
	name: 'reports-view',
	components: { Screen, UiButton, UiLabel },
	mixins: [download],
	data() {
		return {
			donatedYTD: '$15,345.21',
			pendingDeposit: '$455.32',
			nextDeposit: '08/31/2018',
			newDonors: 0,
			totalDonors: 124,
			year: new Date().getFullYear()
		}
	},
	computed: {
		...mapState({
			general: ({ reportList }) => reportList.general,
			authToken: ({ me }) => me.token
		})
	},
	methods: {
		...mapActions(['getGeneralReport']),
		handleMonthlyDownload() {
			window.analytics.track('download_monthly_report')
			this.postParameters(
				'/reports/last-month-summary/csv',
				{},
				this.authToken
			)
		},
		handleYearlyDownload() {
			window.analytics.track('download_annual_report')
			this.postParameters(
				'/reports/last-year-summary/csv?year=' + this.year,
				{},
				this.authToken
			)
		},
		handleTaxDownload() {
			this.postParameters(
				'/reports/tax-summary/csv?year=2021', //new Date().getFullYear() - 1
				{},
				this.authToken
			)
		},
		numberFormat,
		getLastMonth,
		getYears() {
			if (
				this.general.donatedYears &&
				this.general.donatedYears.length > 0
			) {
				return this.general.donatedYears
			}
			return [this.year]
		}
	},
	mounted() {
		this.getGeneralReport()
	}
}
</script>

<style lang="scss">
@import '../../../styles/variables';
.reports-view {
	padding: 20px;
}
.report-panels {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-column-gap: 20px;
	min-height: 540px;
	height: 100%;
	.panel {
		border: 1px solid $roundup-light-grey;
		border-radius: 20px;
		position: relative;
		display: block;
		text-align: center;
		padding: 20px 20px 100px 20px;
		text-decoration: none;
		color: $roundup-charcoal;
		&:hover {
			background: $roundup-lightest-grey;
		}
		h3 {
			color: $roundup-charcoal;
		}
		.value {
			font-size: 48px;
			color: $roundup-charcoal;
			span {
				display: block;
				font-size: 14px;
			}
		}
		.selector {
			text-align: center;
			font-size: 48px;
			padding: 0px 25px;
			font-weight: $font-bold;
			border: 1px solid $roundup-light-grey;
			color: $roundup-charcoal;
			border-radius: 8px;
		}
		.icon {
			display: inline-block;
			margin-top: 30px;
			margin-bottom: 30px;
			background-size: 100% auto;
			&.icon-coin {
				background-image: url('../../../assets/taxDeductible.png');
				width: 55px;
				height: 49px;
			}
			&.icon-people {
				background-image: url('../../../assets/donors.png');
				width: 54px;
				height: 53px;
			}
			&.icon-eye {
				background-image: url('../../../assets/eye.png');
				width: 74px;
				height: 65px;
			}
		}
		.panel-footer {
			position: absolute;
			bottom: 20px;
			left: 0px;
			width: 100%;
			span {
				display: block;
			}
		}
		&.panel-donation {
			.value {
				strong {
					color: $roundup-light-green;
				}
			}
		}
	}
}
</style>
