var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('screen',{staticClass:"reports-view",attrs:{"size":"large"}},[_c('h1',{attrs:{"slot":"title"},slot:"title"},[_vm._v("General Reports")]),_c('section',{staticClass:"report-panels",attrs:{"slot":"body"},slot:"body"},[_c('router-link',{staticClass:"panel panel-donation",attrs:{"to":{ name: 'reports.donations' }}},[_c('h3',[_vm._v("Forecasted Donations")]),_c('div',{staticClass:"icon icon-coin"}),_c('div',{staticClass:"value"},[_c('strong',[_vm._v(_vm._s(_vm.numberFormat(_vm.general.pendingDeposit)))]),_c('span',[_vm._v("accumulated change this month")])]),_c('div',{staticClass:"panel-footer"},[_c('div',{staticClass:"subscript"},[_c('strong',[_vm._v(_vm._s(_vm.numberFormat(_vm.general.donatedYTD)))]),_vm._v(" donated YTD ")]),_c('Roundup-Button',{staticClass:"mt-5",attrs:{"color":"primary"}},[_vm._v("View Report")])],1)]),_c('router-link',{staticClass:"panel",attrs:{"to":{ name: 'reports.donors' }}},[_c('h3',[_vm._v("New Donors")]),_c('div',{staticClass:"icon icon-people"}),_c('div',{staticClass:"value"},[_c('strong',[_vm._v(_vm._s(_vm.general.newDonors > 0 ? '+' + _vm.general.newDonors : 0))]),_c('span',[_vm._v("donors this week")])]),_c('div',{staticClass:"panel-footer"},[_c('div',{staticClass:"subscript"},[_c('strong',[_vm._v(_vm._s(_vm.general.totalDonors))]),_vm._v(" active donors ")]),_c('Roundup-Button',{staticClass:"mt-5",attrs:{"color":"primary"}},[_vm._v("View Report")])],1)]),_c('a',{staticClass:"panel",on:{"click":function($event){$event.preventDefault();return _vm.handleMonthlyDownload.apply(null, arguments)}}},[_c('h3',[_vm._v("Monthly Report")]),_c('div',{staticClass:"value"},[_c('strong',[_vm._v(_vm._s(_vm.getLastMonth()))])]),_c('div',{staticClass:"panel-footer"},[_c('Roundup-Button',{staticClass:"mt-5",attrs:{"color":"primary"}},[_vm._v("Download")])],1)]),_c('div',{staticClass:"panel"},[_c('h3',[_vm._v("Yearly Report")]),_c('ui-label',{style:({
					textAlign: 'center',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					marginTop: '20px'
				})},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.year),expression:"year"}],staticClass:"value selector",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.year=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.getYears()),function(yr,idx){return _c('option',{key:idx,style:({
							display: 'flex',
							alignContent: 'center',
							alignItems: 'center',
							justifyContent: 'center'
						})},[_vm._v(" "+_vm._s(yr)+" ")])}),0)]),_c('p',[_vm._v(" We show each donation for each user by date. So it's easy to upload into your CRM. ")]),_c('p',[_vm._v("With ♥, "),_c('br'),_vm._v("The RoundUp Team")]),_c('p'),_c('a',{staticClass:"panel-footer",on:{"click":function($event){$event.preventDefault();return _vm.handleYearlyDownload.apply(null, arguments)}}},[_c('Roundup-Button',{staticClass:"mt-5",attrs:{"color":"primary"}},[_vm._v("Download")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }